<template>
  <b-card
    aria-hidden="true"
    class="mb-0"
  >
    <!-- Header: Personal Info -->
    <div class="d-flex mb-2 mt-0">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Información Personal
      </h4>
    </div>

    <!-- Sistema Info: Input Fields -->
    <b-row>

      <!-- Field: Avatar -->
      <b-col
        cols="12"
        md="12"
      >

        <b-form-group
          label-for="avatar"
        >
          <!-- Media -->
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :src="usuario.avatar"
                :text="usuario.nombres == ''
                  ? 'U'
                  : avatarText(`${usuario.nombres}
                    ${usuario.primerApellido} ${usuario.segundoApellido}`)"
                size="90px"
                rounded
                variant="light-success"
                :title="usuario.nombres == ''
                  ? 'U'
                  : avatarText(`${usuario.nombres}
                    ${usuario.primerApellido} ${usuario.segundoApellido}`)"
              />
            </template>
            <h4 class="mb-1">
              <span v-if="usuario.rut">
                {{ usuario.rut }}
              </span>
              <span v-if="usuario.rut && usuario.nombres">
                <feather-icon
                  icon="ChevronsRightIcon"
                />
              </span>
              <span v-if="usuario.nombre">
                {{ usuario.nombres }}
              </span>
              <span v-if="usuario.primerApellido">
                {{ usuario.primerApellido }}
              </span>
              <span v-if="usuario.segundoApellido">
                {{ usuario.segundoApellido }}
              </span>
            </h4>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click="$refs.refInputEl.click()"
              >
                <input
                  :id="usuario.avatar"
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  accept="image/png,image/jpeg,image/jpg"
                  @input="inputImageRenderer"
                  @change="onFileSelected"
                >
                <span class="d-none d-sm-inline">
                  Seleccionar
                </span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-1"
                @click="usuario.avatar = ''"
              >
                <span class="d-none d-sm-inline">Eliminar</span>
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
            </div>
          </b-media>
        </b-form-group>
      </b-col>

      <!-- Field: Correo -->
      <b-col
        cols="12"
        :md="processType === 'create'
          ? '6'
          : '12'"
      >
        <b-form-group
          label="Correo *"
          label-for="correo"
        >
          <b-form-input
            id="correo"
            v-model="usuario.email"
            placeholder="Ingresa el correo"
            :state="$v.usuario.email.$invalid && $v.usuario.email.$dirty
              ? false
              : null"
          />
          <b-form-invalid-feedback
            v-if="$v.usuario.email.$invalid && $v.usuario.email.$dirty"
            id="correoInfo"
          >
            El campo correo
            <span v-if="!$v.usuario.email.required">
              es requerido.
            </span>
            <span v-if="!$v.usuario.email.email">
              debe ser un correo válido.
            </span>
            <span v-if="!$v.usuario.email.maxLength">
              debe tener máximo 80 caracteres.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Field: Password -->
      <b-col
        v-if="processType === 'create'"
        cols="12"
        md="6"
      >
        <b-form-group
          label="Contraseña *"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="usuario.password"
            type="password"
            placeholder="Ingresa la contraseña"
            :state="$v.usuario.password.$invalid && $v.usuario.password.$dirty
              ? false
              : null"
          />
          <b-form-invalid-feedback
            v-if="$v.usuario.password.$invalid && $v.usuario.password.$dirty"
            id="passwordInfo"
          >
            El campo contraseña
            <span v-if="!$v.usuario.password.required">
              es requerido.
            </span>
            <span v-if="!$v.usuario.password.minLength">
              debe tener mínimo 6 caracteres.
            </span>
            <span v-if="!$v.usuario.password.maxLength">
              debe tener máximo 30 caracteres.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Field: Rut -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Rut *"
          label-for="rut"
        >
          <b-form-input
            id="rut"
            v-model="usuario.rut"
            placeholder="Ingresa el rut"
            :state="$v.usuario.rut.$invalid && $v.usuario.rut.$dirty
              ? false
              : null"
          />
          <b-form-invalid-feedback
            v-if="$v.usuario.rut.$invalid && $v.usuario.rut.$dirty"
            id="rutInfo"
          >
            El campo rut
            <span v-if="!$v.usuario.rut.required">
              es requerido.
            </span>
            <span v-if="!$v.usuario.rut.maxLength">
              debe tener máximo 15 caracteres.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Field: Nombres -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Nombres *"
          label-for="nombres"
        >
          <b-form-input
            id="nombres"
            v-model="usuario.nombres"
            placeholder="Ingresa el o los nombres"
            :state="$v.usuario.nombres.$invalid && $v.usuario.nombres.$dirty
              ? false
              : null"
          />
          <b-form-invalid-feedback
            v-if="$v.usuario.nombres.$invalid && $v.usuario.nombres.$dirty"
            id="nombresInfo"
          >
            El campo nombres
            <span v-if="!$v.usuario.nombres.required">
              es requerido.
            </span>
            <span v-if="!$v.usuario.nombres.maxLength">
              debe tener máximo 150 caracteres.
            </span>
          </b-form-invalid-feedback>

        </b-form-group>
      </b-col>

      <!-- Field: primerApellido -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Apellido Paterno *"
          label-for="primerApellido"
        >
          <b-form-input
            id="primerApellido"
            v-model="usuario.primerApellido"
            placeholder="Ingresa el apellido paterno"
            :state="$v.usuario.primerApellido.$invalid
              && $v.usuario.primerApellido.$dirty
              ? false
              : null"
          />
          <b-form-invalid-feedback
            v-if="$v.usuario.primerApellido.$invalid
              && $v.usuario.primerApellido.$dirty"
            id="primerApellidoInfo"
          >
            El campo apellido paterno
            <span v-if="!$v.usuario.primerApellido.required">
              es requerido.
            </span>
            <span v-if="!$v.usuario.primerApellido.maxLength">
              debe tener máximo 100 caracteres.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Field: segundoApellido -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Apellido Materno *"
          label-for="segundoApellido"
        >
          <b-form-input
            id="segundoApellido"
            v-model="usuario.segundoApellido"
            placeholder="Ingresa el apellido materno"
            :state="$v.usuario.segundoApellido.$invalid
              && $v.usuario.segundoApellido.$dirty
              ? false
              : null"
          />
          <b-form-invalid-feedback
            v-if="$v.usuario.segundoApellido.$invalid
              && $v.usuario.segundoApellido.$dirty"
            id="segundoApellidoInfo"
          >
            El campo apellido materno
            <span v-if="!$v.usuario.segundoApellido.required">
              es requerido.
            </span>
            <span v-if="!$v.usuario.segundoApellido.maxLength">
              debe tener máximo 100 caracteres.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

    </b-row>

    <colLinea
      class="mt-1 mb-0"
    />
    <!-- Header: Personal Info -->
    <div class="d-flex mb-2 mt-3">
      <feather-icon
        icon="UnlockIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Permisos
      </h4>
    </div>

    <b-row>
      <!-- Field: Roles -->
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Rol *"
          label-for="rol"
          :state="$v.usuario.rol.$invalid
            && $v.usuario.rol.$dirty
            ? false
            : null"
        >
          <v-select
            v-if="processType === 'create'"
            v-model="usuario.rol"
            placeholder="Selecciona un rol..."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="rolesOption"
            @input="changeRol"
          />

          <v-select
            v-else-if="processType === 'updated'"
            v-model="usuario.nombreRol"
            placeholder="Selecciona un rol..."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="rolesOption"
            :disabled="true"
          />

        </b-form-group>
        <b-form-invalid-feedback
          v-if="$v.usuario.rol.$invalid
            && $v.usuario.rol.$dirty"
          id="rolInfo"
        >
          El campo rol
          <span v-if="!$v.usuario.rol.required">
            es requerido.
          </span>
        </b-form-invalid-feedback>
      </b-col>

      <!-- Field: Establecimientos -->
      <b-col
        v-if="disabledEstablecimiento"
        cols="12"
        md="12"
      >
        <b-form-group
          label="Establecimiento"
          label-for="establecimiento"
        >
          <!-- multiple -->
          <v-select
            v-if="processType === 'create'"
            v-model="usuario.idEstablecimiento"
            placeholder="Selecciona un establecimiento..."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :reduce="option => option.id"
            :options="establecimientoOption"
            @input="changeEstablecimiento"
          >
            <template #option="{ title, insignia }">
              <b-avatar
                size="32"
                :src="insignia"
                :text="avatarText(title)"
              />
              <span> {{ title }}</span>
            </template>
          </v-select>

          <v-select
            v-else-if="processType === 'updated'"
            v-model="usuario.nombreEstablecimiento"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :disabled="true"
          />
          <!--
          <v-select
            v-else-if="processType === 'updated'"
            v-model="usuario.nombreEstablecimiento"
            placeholder="Selecciona un rol..."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="rolesOption"
            :disabled="true"
          /> -->
        </b-form-group>
      </b-col>

    </b-row>

    <template
      v-if="disabledCursosAsignaturas"
    >
      <!-- Header: Personal Info -->
      <div class="d-flex mb-1 mt-3">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Asignaturas por Curso
        </h4>
      </div>

      <b-row>
        <usuariosFormCursos
          v-for="(curso, index) in cursos"
          :key="index"
          :curso="curso"
          :asignaturas.sync="usuario.asignaturas"
          :processType="processType"
        />
      </b-row>
    </template>

    <colLinea
      class="mt-2 mb-0"
    />

    <!-- Action Buttons -->
    <!-- v-if="cargando" -->
    <btnSubmit
      variant="primary"
      :btnText="btnSubmit"
      @processBtn="submitOption"
    />

  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BAvatar, BFormInvalidFeedback,
  BMedia, BButton, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength, email, minLength,
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import UsuariosFormCursos from './UsuariosFormCursos.vue'
import btnSubmit from '../../components/Form/btnSubmit.vue'
import colLinea from '../../components/Form/colLinea.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BCard,

    vSelect,
    UsuariosFormCursos,
    btnSubmit,
    colLinea,
  },
  mixins: [validationMixin],
  props: {
    usuario: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Usuario',
    },
    processType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dir: 'ltr',
      rolesOption: [],
      establecimientoOption: [],
      cursos: [],
    }
  },
  validations() {
    if (this.processType === 'create') {
      return {
        usuario: {
          email: {
            required,
            email,
            maxLength: maxLength(80),
          },
          password: {
            required,
            minLength: minLength(6),
            maxLength: maxLength(30),
          },
          rut: {
            required,
            maxLength: maxLength(15),
          },
          nombres: {
            required,
            maxLength: maxLength(150),
          },
          primerApellido: {
            required,
            maxLength: maxLength(100),
          },
          segundoApellido: {
            required,
            maxLength: maxLength(100),
          },
          rol: {
            required,
          },
          estado: {},
        },
      }
    }
    if (this.processType === 'updated') {
      return {
        usuario: {
          email: {
            required,
            email,
            maxLength: maxLength(80),
          },
          rut: {
            required,
            maxLength: maxLength(15),
          },
          nombres: {
            required,
            maxLength: maxLength(150),
          },
          primerApellido: {
            required,
            maxLength: maxLength(100),
          },
          segundoApellido: {
            required,
            maxLength: maxLength(100),
          },
          rol: {
            // required,
          },
        },
      }
    }
    return this.usuario
  },
  computed: {
    ...mapGetters({
      getEstablecimientosActivos: 'establecimientos/getEstablecimientosActivos',
      getRoles: 'roles/getRoles',
      user: 'auth/user',
      getCursosActivosEstablecimiento: 'cursos/getCursosActivosEstablecimiento',
    }),
    disabledEstablecimiento() {
      return this.setDisabledEstablecimiento()
    },
    disabledCursosAsignaturas() {
      return this.setDisabledEnrolamiento()
    },
  },
  watch: {
    getRoles() {
      this.setRolesOptions(this.getRoles)
      if (this.processType === 'updated') {
        this.setUpdate()
      }
    },
    getEstablecimientosActivos() {
      this.setEstablecimientosActivosOptions(this.getEstablecimientosActivos)
    },
  },
  mounted() {
    this.fetchRoles()
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchRoles: 'roles/fetchRoles',
      fetchEstablecimientosActivos: 'establecimientos/fetchEstablecimientosActivos',
      fetchCursosActivosEstablecimiento: 'cursos/fetchCursosActivosEstablecimiento',
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
    }),
    setUpdate() {
      const rol = this.rolesOption.find(
        ro => ro.title === this.usuario.nombreRol,
      )
      this.usuario.nombreRol = rol
      this.attempt().then(() => {
        const { idEstablecimientoActivo } = this.user
        this.fetchCursosActivosEstablecimiento(idEstablecimientoActivo).then(() => {
          this.cursos = this.getCursosActivosEstablecimiento
        })
      })
    },
    submitOption() {
      this.$v.usuario.$touch()
      if (!this.$v.usuario.$invalid) {
        this.$emit('processForm', this.usuario)
      }
    },
    setDisabledEnrolamiento() {
      let retorna = false
      if (this.processType === 'updated') {
        let isDocente = false
        if (typeof this.usuario.nombreRol !== 'undefined') {
          const { title } = this.usuario.nombreRol
          if (
            title === 'Docente'
            || title === 'Docente Pie'
            || title === 'Asistente'
          ) {
            isDocente = true
          }
        }
        retorna = this.cursos.length > 0 && isDocente
      } else {
        retorna = this.cursos.length
      }
      return retorna
    },
    esRolAdmin() {
      const { nombreRol } = this.usuario
      return nombreRol === 'Super Administrador'
        || nombreRol === 'Administrador Daem'
    },
    setDisabledEstablecimiento() {
      let response
      if (this.processType === 'create') {
        response = this.establecimientoOption.length > 0
          ? this.esRolAdmin()
          : false
      } else if (this.processType === 'updated') {
        response = this.esRolAdmin()
      }
      return response
    },
    changeRol() {
      const { rol } = this.usuario
      if (rol.title !== 'Super Administrador'
        && rol.title !== 'Administrador Daem') {
        this.fetchEstablecimientosActivos().then(() => {
          this.changeEstablecimiento()
          this.setDisabledEstablecimiento()
        })
      } else {
        this.resetEstablecimientos()
      }
    },
    changeEstablecimiento() {
      const {
        rol,
        idEstablecimiento,
      } = this.usuario
      this.cursos = []
      if (rol.title === 'Docente'
        || rol.title === 'Docente Pie'
        || rol.title === 'Asistente') {
        this.fetchCursosActivosEstablecimiento(idEstablecimiento).then(() => {
          this.cursos = this.getCursosActivosEstablecimiento
        })
      }
    },
    setRolesOptions(roles) {
      this.rolesOption = []
      if (this.user.rolActivo === 'Super Administrador'
        || this.user.rolActivo === 'Administrador Daem'
      ) {
        roles.forEach(rol => {
          this.rolesOption.push({
            id: rol.id,
            title: rol.name,
          })
        })
      } else {
        roles.forEach(rol => {
          if (rol.name !== 'Super Administrador'
            && rol.name !== 'Administrador Daem'
          ) {
            this.rolesOption.push({
              id: rol.id,
              title: rol.name,
            })
          }
        })
      }
    },
    setEstablecimientosActivosOptions(establecimientosActivos) {
      // solo si es super admin y admin daem muestra todos los establecimientos
      this.resetEstablecimientos()
      if (this.user.rolActivo === 'Super Administrador'
        || this.user.rolActivo === 'Administrador Daem'
      ) {
        establecimientosActivos.forEach(establecimientoActivo => {
          this.establecimientoOption.push({
            id: establecimientoActivo.id,
            title: establecimientoActivo.nombre,
            insignia: establecimientoActivo.insignia,
          })
        })
      } else {
        const { idEstablecimientoActivo } = this.user
        const establecimientoActivo = establecimientosActivos.find(
          ea => ea.id === idEstablecimientoActivo,
        )
        this.establecimientoOption.push({
          id: establecimientoActivo.id,
          title: establecimientoActivo.nombre,
          insignia: establecimientoActivo.insignia,
        })
        this.usuario.idEstablecimiento = establecimientoActivo.id
        this.setDisabledEstablecimiento()
      }
    },
    resetEstablecimientos() {
      this.establecimientoOption = []
      this.usuario.idEstablecimiento = null
    },

    // Avatar
    onFileSelected(e) {
      // Obtiene primera Imagen
      const file = e.target.files[0]
      this.cargarImg(file)
    },
    cargarImg(file) {
      // Objeto que permite trabajar con archivos
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.usuario.avatar = e.target.result
      }
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.usuario.avatar = base64
    })

    return {
      avatarText,
      previewEl,
      refInputEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
