<template>
  <usuarioForm
    btnSubmit="Crear Usuario"
    processType="create"
    :usuario="usuarioData"
    @processForm="agregarUsuario"
  />
</template>

<script>

import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import usuarioForm from './components/UsuariosForm.vue'

export default {
  components: {
    usuarioForm,
  },
  data() {
    return {
      usuarioData: {
        correo: '',
        password: '',
        avatar: null,
        rut: '',
        nombres: '',
        primerApellido: '',
        segundoApellido: '',
        rol: '',
        idEstablecimiento: '',
        estado: null,
        asignaturas: [],
      },
    }
  },
  methods: {
    ...mapActions({ createUsuario: 'usuarios/addUsuarios' }),
    // agregarUsuario(usuario) {
    // },
    agregarUsuario(usuario) {
      this.createUsuario(usuario).then(() => {
        const errorUsuarios = store.state.usuarios
        const errorMessage = errorUsuarios.errorMessage.errors
        if (!errorUsuarios.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuario creado con éxito 👍',
              icon: 'CheckIcon',
              text: `El usuario "${usuario.nombres} ${usuario.primerApellido}
                ${usuario.segundoApellido}" fue creado con éxito!`,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
            position: 'bottom-right',
          })
          this.$router.replace({
            name: 'usuarios',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          let title = 'Registro Duplicado!'
          let text = 'Ingreso de datos fraudulento!'

          if (errorMessage.email && errorMessage.rut) {
            if (errorMessage.email[0] === 'validation.unique' && errorMessage.rut[0] === 'validation.unique') {
              title = 'Registro Duplicado!'
              text = 'El correo y el rut que intenta ingresar ya se encuentran registrados.'
            } else if (errorMessage.email[0] === 'validation.unique') {
              title = 'Registro Duplicado!'
              text = 'El correo que intenta ingresar ya se encuentra registrado.'
            } else if (errorMessage.rut[0] === 'validation.unique') {
              title = 'Registro Duplicado!'
              text = 'El rut que intenta ingresar ya se encuentra registrado.'
            }
          } else if (errorMessage.email) {
            if (errorMessage.email[0] === 'validation.unique') {
              title = 'Registro Duplicado!'
              text = 'El correo que intenta ingresar ya se encuentra registrado.'
            }
          } else if (errorMessage.rut) {
            if (errorMessage.rut[0] === 'validation.unique') {
              title = 'Registro Duplicado!'
              text = 'El rut que intenta ingresar ya se encuentra registrado.'
            }
          }
          this.$swal({
            title,
            text,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
