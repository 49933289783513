var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-0",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"d-flex mb-2 mt-0"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Información Personal ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"avatar"}},[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.usuario.avatar,"text":_vm.usuario.nombres == ''
                ? 'U'
                : _vm.avatarText(((_vm.usuario.nombres) + "\n                  " + (_vm.usuario.primerApellido) + " " + (_vm.usuario.segundoApellido))),"size":"90px","rounded":"","variant":"light-success","title":_vm.usuario.nombres == ''
                ? 'U'
                : _vm.avatarText(((_vm.usuario.nombres) + "\n                  " + (_vm.usuario.primerApellido) + " " + (_vm.usuario.segundoApellido)))}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[(_vm.usuario.rut)?_c('span',[_vm._v(" "+_vm._s(_vm.usuario.rut)+" ")]):_vm._e(),(_vm.usuario.rut && _vm.usuario.nombres)?_c('span',[_c('feather-icon',{attrs:{"icon":"ChevronsRightIcon"}})],1):_vm._e(),(_vm.usuario.nombre)?_c('span',[_vm._v(" "+_vm._s(_vm.usuario.nombres)+" ")]):_vm._e(),(_vm.usuario.primerApellido)?_c('span',[_vm._v(" "+_vm._s(_vm.usuario.primerApellido)+" ")]):_vm._e(),(_vm.usuario.segundoApellido)?_c('span',[_vm._v(" "+_vm._s(_vm.usuario.segundoApellido)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"id":_vm.usuario.avatar,"type":"file","accept":"image/png,image/jpeg,image/jpg"},on:{"input":_vm.inputImageRenderer,"change":_vm.onFileSelected}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(" Seleccionar ")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.usuario.avatar = ''}}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Eliminar")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1)],1)])],1)],1),_c('b-col',{attrs:{"cols":"12","md":_vm.processType === 'create'
        ? '6'
        : '12'}},[_c('b-form-group',{attrs:{"label":"Correo *","label-for":"correo"}},[_c('b-form-input',{attrs:{"id":"correo","placeholder":"Ingresa el correo","state":_vm.$v.usuario.email.$invalid && _vm.$v.usuario.email.$dirty
            ? false
            : null},model:{value:(_vm.usuario.email),callback:function ($$v) {_vm.$set(_vm.usuario, "email", $$v)},expression:"usuario.email"}}),(_vm.$v.usuario.email.$invalid && _vm.$v.usuario.email.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"correoInfo"}},[_vm._v(" El campo correo "),(!_vm.$v.usuario.email.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e(),(!_vm.$v.usuario.email.email)?_c('span',[_vm._v(" debe ser un correo válido. ")]):_vm._e(),(!_vm.$v.usuario.email.maxLength)?_c('span',[_vm._v(" debe tener máximo 80 caracteres. ")]):_vm._e()]):_vm._e()],1)],1),(_vm.processType === 'create')?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Contraseña *","label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"Ingresa la contraseña","state":_vm.$v.usuario.password.$invalid && _vm.$v.usuario.password.$dirty
            ? false
            : null},model:{value:(_vm.usuario.password),callback:function ($$v) {_vm.$set(_vm.usuario, "password", $$v)},expression:"usuario.password"}}),(_vm.$v.usuario.password.$invalid && _vm.$v.usuario.password.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"passwordInfo"}},[_vm._v(" El campo contraseña "),(!_vm.$v.usuario.password.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e(),(!_vm.$v.usuario.password.minLength)?_c('span',[_vm._v(" debe tener mínimo 6 caracteres. ")]):_vm._e(),(!_vm.$v.usuario.password.maxLength)?_c('span',[_vm._v(" debe tener máximo 30 caracteres. ")]):_vm._e()]):_vm._e()],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Rut *","label-for":"rut"}},[_c('b-form-input',{attrs:{"id":"rut","placeholder":"Ingresa el rut","state":_vm.$v.usuario.rut.$invalid && _vm.$v.usuario.rut.$dirty
            ? false
            : null},model:{value:(_vm.usuario.rut),callback:function ($$v) {_vm.$set(_vm.usuario, "rut", $$v)},expression:"usuario.rut"}}),(_vm.$v.usuario.rut.$invalid && _vm.$v.usuario.rut.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"rutInfo"}},[_vm._v(" El campo rut "),(!_vm.$v.usuario.rut.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e(),(!_vm.$v.usuario.rut.maxLength)?_c('span',[_vm._v(" debe tener máximo 15 caracteres. ")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Nombres *","label-for":"nombres"}},[_c('b-form-input',{attrs:{"id":"nombres","placeholder":"Ingresa el o los nombres","state":_vm.$v.usuario.nombres.$invalid && _vm.$v.usuario.nombres.$dirty
            ? false
            : null},model:{value:(_vm.usuario.nombres),callback:function ($$v) {_vm.$set(_vm.usuario, "nombres", $$v)},expression:"usuario.nombres"}}),(_vm.$v.usuario.nombres.$invalid && _vm.$v.usuario.nombres.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"nombresInfo"}},[_vm._v(" El campo nombres "),(!_vm.$v.usuario.nombres.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e(),(!_vm.$v.usuario.nombres.maxLength)?_c('span',[_vm._v(" debe tener máximo 150 caracteres. ")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Apellido Paterno *","label-for":"primerApellido"}},[_c('b-form-input',{attrs:{"id":"primerApellido","placeholder":"Ingresa el apellido paterno","state":_vm.$v.usuario.primerApellido.$invalid
            && _vm.$v.usuario.primerApellido.$dirty
            ? false
            : null},model:{value:(_vm.usuario.primerApellido),callback:function ($$v) {_vm.$set(_vm.usuario, "primerApellido", $$v)},expression:"usuario.primerApellido"}}),(_vm.$v.usuario.primerApellido.$invalid
            && _vm.$v.usuario.primerApellido.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"primerApellidoInfo"}},[_vm._v(" El campo apellido paterno "),(!_vm.$v.usuario.primerApellido.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e(),(!_vm.$v.usuario.primerApellido.maxLength)?_c('span',[_vm._v(" debe tener máximo 100 caracteres. ")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Apellido Materno *","label-for":"segundoApellido"}},[_c('b-form-input',{attrs:{"id":"segundoApellido","placeholder":"Ingresa el apellido materno","state":_vm.$v.usuario.segundoApellido.$invalid
            && _vm.$v.usuario.segundoApellido.$dirty
            ? false
            : null},model:{value:(_vm.usuario.segundoApellido),callback:function ($$v) {_vm.$set(_vm.usuario, "segundoApellido", $$v)},expression:"usuario.segundoApellido"}}),(_vm.$v.usuario.segundoApellido.$invalid
            && _vm.$v.usuario.segundoApellido.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"segundoApellidoInfo"}},[_vm._v(" El campo apellido materno "),(!_vm.$v.usuario.segundoApellido.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e(),(!_vm.$v.usuario.segundoApellido.maxLength)?_c('span',[_vm._v(" debe tener máximo 100 caracteres. ")]):_vm._e()]):_vm._e()],1)],1)],1),_c('colLinea',{staticClass:"mt-1 mb-0"}),_c('div',{staticClass:"d-flex mb-2 mt-3"},[_c('feather-icon',{attrs:{"icon":"UnlockIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Permisos ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Rol *","label-for":"rol","state":_vm.$v.usuario.rol.$invalid
          && _vm.$v.usuario.rol.$dirty
          ? false
          : null}},[(_vm.processType === 'create')?_c('v-select',{attrs:{"placeholder":"Selecciona un rol...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.rolesOption},on:{"input":_vm.changeRol},model:{value:(_vm.usuario.rol),callback:function ($$v) {_vm.$set(_vm.usuario, "rol", $$v)},expression:"usuario.rol"}}):(_vm.processType === 'updated')?_c('v-select',{attrs:{"placeholder":"Selecciona un rol...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.rolesOption,"disabled":true},model:{value:(_vm.usuario.nombreRol),callback:function ($$v) {_vm.$set(_vm.usuario, "nombreRol", $$v)},expression:"usuario.nombreRol"}}):_vm._e()],1),(_vm.$v.usuario.rol.$invalid
          && _vm.$v.usuario.rol.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"rolInfo"}},[_vm._v(" El campo rol "),(!_vm.$v.usuario.rol.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e()]):_vm._e()],1),(_vm.disabledEstablecimiento)?_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Establecimiento","label-for":"establecimiento"}},[(_vm.processType === 'create')?_c('v-select',{attrs:{"placeholder":"Selecciona un establecimiento...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","reduce":function (option) { return option.id; },"options":_vm.establecimientoOption},on:{"input":_vm.changeEstablecimiento},scopedSlots:_vm._u([{key:"option",fn:function(ref){
          var title = ref.title;
          var insignia = ref.insignia;
return [_c('b-avatar',{attrs:{"size":"32","src":insignia,"text":_vm.avatarText(title)}}),_c('span',[_vm._v(" "+_vm._s(title))])]}}],null,false,4029039664),model:{value:(_vm.usuario.idEstablecimiento),callback:function ($$v) {_vm.$set(_vm.usuario, "idEstablecimiento", $$v)},expression:"usuario.idEstablecimiento"}}):(_vm.processType === 'updated')?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"disabled":true},model:{value:(_vm.usuario.nombreEstablecimiento),callback:function ($$v) {_vm.$set(_vm.usuario, "nombreEstablecimiento", $$v)},expression:"usuario.nombreEstablecimiento"}}):_vm._e()],1)],1):_vm._e()],1),(_vm.disabledCursosAsignaturas)?[_c('div',{staticClass:"d-flex mb-1 mt-3"},[_c('feather-icon',{attrs:{"icon":"BookIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Asignaturas por Curso ")])],1),_c('b-row',_vm._l((_vm.cursos),function(curso,index){return _c('usuariosFormCursos',{key:index,attrs:{"curso":curso,"asignaturas":_vm.usuario.asignaturas,"processType":_vm.processType},on:{"update:asignaturas":function($event){return _vm.$set(_vm.usuario, "asignaturas", $event)}}})}),1)]:_vm._e(),_c('colLinea',{staticClass:"mt-2 mb-0"}),_c('btnSubmit',{attrs:{"variant":"primary","btnText":_vm.btnSubmit},on:{"processBtn":_vm.submitOption}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }