<template>
  <!-- PERMISSION TABLE -->
  <!-- types -->
  <b-col
    cols="12"
    lg="3"
    md="4"
  >
    <b-card
      no-body
      class="border mt-1"
    >
      <b-table
        striped
        responsive
        class="mb-0"
        :items="itemsAsignaturas"
        :fields="field"
      >

        <template #cell(module)>
          {{ curso.nombreGrado }}
        </template>
        <template #cell(nombreAsignatura)="data">
          <b-form-checkbox
            v-model="data.item.value"
            @change="changeAsignatura(data.item)"
          >
            <!-- :checked="data.item.id" -->
            {{ data.item.nombreAsignatura }}
          </b-form-checkbox>
        </template>
      </b-table>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BTable, BFormCheckbox, BCol,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

import spinner from '../../components/spinner.vue'

export default {
  components: {
    BCard,
    BTable,
    BFormCheckbox,
    BCol,
    spinner,
  },
  props: {
    curso: {
      type: Object,
      required: true,
    },
    asignaturas: {
      type: Array,
      required: true,
    },
    processType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cargando: false,
      itemsAsignaturas: [],
      chkAsignaturas: [],
      field: [
        {
          key: 'nombreAsignatura',
          label: '',
          sortable: false,
          thStyle: {
            width: '250px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  mounted() {
    this.field[0].label = `${this.curso.nombreGrado} ${this.curso.letra}`
    this.loadAsignaturas(this.curso.idGrado)
  },
  methods: {
    ...mapActions({
      fetchAsignaturasPorGrado: 'asignaturas/fetchAsignaturasActivosGrado',
    }),
    async loadAsignaturas(idGrado) {
      const response = await this.fetchAsignaturasPorGrado(idGrado)
      if (this.processType === 'updated') {
        this.setAsignaturasUsuario(response)
      } else {
        this.itemsAsignaturas = response
      }
    },
    setAsignaturasUsuario(asignaturas) {
      asignaturas.forEach(item => {
        const asignaturasCurso = this.asignaturas.filter(a => a.idCurso === this.curso.id)
        let value = false
        if (asignaturasCurso.length > 0) {
          asignaturasCurso.forEach(asignatura => {
            if (asignatura.idAsignatura === item.id) {
              value = true
            }
          })
        }
        this.itemsAsignaturas.push({
          id: item.id,
          idGrado: item.idGrado,
          nombreAsignatura: item.nombreAsignatura,
          nombreGrado: item.nombreGrado,
          value,
        })
      })
      this.cargando = false
    },
    changeAsignatura(asignatura) {
      const filterAsignatura = this.asignaturas.filter(a => a.idAsignatura === asignatura.id && a.idCurso === this.curso.id)
      if (asignatura.value) { // Agregar
        if (filterAsignatura.length === 0) { // solo si no existe el mismo
          this.asignaturas.push({
            idAsignatura: asignatura.id,
            idCurso: this.curso.id,
            idGrado: asignatura.idGrado,
          })
        }
      } else if (filterAsignatura.length === 1) { // Eliminar
        this.asignaturas.forEach((asig, index) => {
          if (filterAsignatura[0].idCurso === asig.idCurso) {
            if (filterAsignatura[0].idAsignatura === asig.idAsignatura) {
              this.asignaturas.splice(index, 1)
            }
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
